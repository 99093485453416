<template>
  <el-scrollbar class="elv-landing-apply-container">
    <div class="elv-landing-apply-header">
      <p class="elv-landing-apply-header__title">{{ t('report.welcomeUser', { userName: user?.name }) }}</p>
      <p class="elv-landing-apply-header__info"></p>
    </div>
    <ProjectSettingForm />
  </el-scrollbar>
  <MessageBox
    ref="cancelRequestRef"
    :confirm-button-text="t('button.confirmCancel')"
    :cancel-button-text="t('button.dismiss')"
    :title="t('button.cancelRequest')"
    :loading="cancelRequestLoading"
    @onConfirmEvent="onConfirmRequest"
    @onCancelEvent="onCloseConfirm"
  >
    <template #content>
      <span class="elv-apply-confirm-header__title">{{ t('report.cancelRequestInfo') }}</span></template
    >
  </MessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import MessageBox from '@/components/MessageBox.vue'
import { useGlobalStore } from '@/stores/modules/global'
// import ApplyForTrialForm from './components/ApplyForTrialForm.vue'
import ProjectSettingForm from '@/components/Project/ProjectSettingForm.vue'
import { useUserGlobalStore } from '@/stores/modules/global/user'

const { t } = useI18n()
const router = useRouter()
const globalStore = useGlobalStore()
const userGlobalStore = useUserGlobalStore()
const { user } = storeToRefs(userGlobalStore)

const cancelRequestRef = ref()
const cancelRequestLoading = ref(false)

const onCloseApply = () => {
  router.push({ name: 'landing-pricing' })
}

const onConfirmRequest = async () => {
  try {
    await ProjectApi.projectCancelTrial()
    cancelRequestRef.value?.onCheckMessageBoxDialog()
    globalStore.fetchProjectTrialDetail()
    ElMessage.success(t('message.cancelSuccess'))
    onCloseApply()
  } catch (error: any) {
    ElMessage.error(error?.message)
    console.log(error)
  }
}

const onCloseConfirm = () => {
  cancelRequestRef.value?.onCheckMessageBoxDialog()
}

// const onOpenCancelConfirm = () => {
//   cancelRequestRef.value?.onCheckMessageBoxDialog()
// }
</script>

<style lang="scss" scoped>
.elv-landing-apply-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7.5vh;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
}

.elv-landing-apply-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.elv-landing-apply-header {
  display: flex;
  flex-direction: column;

  .elv-landing-apply-header__title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #0e0f11;
  }

  .elv-landing-apply-header__info {
    width: 572px;
    margin-top: 14px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #aaafb6;
  }

  .elv-landing-apply-header__cancel {
    cursor: pointer;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #636b75;
    margin-top: 54px;
  }
}

.elv-apply-confirm-header__title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1e2024;
  text-align: center;
  word-break: break-word;
}
</style>
